import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  renderImages,
} from "./util/interact.js";

const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [tuneImages, setImages] = useState("")

  const [TokenID, setTokenID] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  useEffect(async () => {
    const { address, status, tunes_nft } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);
    console.log(tunes_nft);
    if (tunes_nft.success == 0){
      setImages(0);
    }
    else{
      setImages(tunes_nft.data);
    }
    addWalletListener();
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("You just changed MetaMask account, please refresh this page.");
          
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the 'Connect Wallet' button on top.");
          setImages("");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
    console.log(walletResponse.tunes_nft);
    if (walletResponse.tunes_nft.success == 0){
      setImages(0);
    }
    else{
      setImages(walletResponse.tunes_nft.data);
    }
  };

  const onMintPressed = async () => {
    const { success, status } = await mintNFT(TokenID);
    setStatus(status);
    // if (success) {
    //   setName("");
    //   setDescription("");
    //   setURL("");
    // }
  };

  const images = document.getElementsByClassName('image_tune');
  console.log(images);

  return (
    <div className="Minter">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
 
      

      <br></br>
      <img id = "logo" src={process.env.PUBLIC_URL+ '/logo-g.gif'}></img>
      <h1 align="center" id="title">Lyrics (for Tunes) </h1> 
      {/* <h2  >($TLYRIC)</h2> */}

      <h4 align="center" class="subtitle">Imaginative, AI-generated lyrical prompts based on names of <a href="https://tunesproject.org/" target="_blank">Tunes</a>. Use these verbatim as part of your next <a href="https://songs.tunesproject.org/" target="_blank">song</a>, or as seed for a broader theme. We invite you to build NFT derivatives on top of this. Design inspired by music apps that swipe up to view lyrics.</h4>
      <h4 align="center" class="subtitle"> All artwork is stored on-chain.</h4>

      <form>
        {/* <h4>Tune TokenID</h4> */}
        <input
          type="text"
          id="tokenID"
          placeholder="Your Tune TokenIDs (eg: 1,511,23)"
          onChange={(event) => setTokenID(event.target.value)}
        />
      </form>
      <button align="center" id="mintButton" onClick={onMintPressed}>
      Mint $TLYRIC
      </button>

      <p id="status" >
        {status}
      </p>
      <div>
      <hr id="line_breaks"></hr>
        <h3 align="center"> Your Tunes </h3>
      {renderImages(tuneImages)}
      </div>
      <hr id="line_breaks"></hr>
      <h3 align="center">
        FAQs
        </h3>

        <p class="ques"><strong>Who can mint this?</strong>&nbsp;</p>
        <p class="ans">Only available to Tunes holders. You can snag a Tunes at <a href="https://opensea.io/collection/tunesproject" target="_blank">OpenSea</a>.&nbsp;</p>
        <p class="ques"><strong>Where can I find my Tunes ID?</strong>&nbsp;</p>
        <p class="ans">Once you connect the wallet, you will be able to see all the Tunes you own. Otherwise, you can also visit your Tunes NFT on OpenSea. Open the &apos;Details&apos; section under it. Find &apos;Token ID&apos;.&nbsp;</p>
        <p class="ques"><strong>How much does it cost?</strong>&nbsp;</p>
        <p class="ans">0.01<span>&Xi;</span> to compensate for the cost and effort behind the AI model.&nbsp;</p>
        <p class="ques"><strong>What properties does it have?</strong>&nbsp;</p>
        <p class="ans">Measures of rarity are open to interpretation.&nbsp;</p>
        <p class="ques"><strong>What if I don&apos;t like my $TLYRIC?</strong>&nbsp;</p>
        <p class="ans">Since these are generated by machines, please embrace the imperfection around narrative or structure. To preserve the nature of this project, we will not edit content unless there is a significant design or grammatical error. Please fill out <a href ="https://forms.gle/zGBjy6xEyGdwiCKc9" target="_blank">this form</a> to request an edit.&nbsp;</p>
        <p class="ques"><strong>What&apos;s the roadmap?&nbsp;</strong></p>
        <p class="ans">We do not intend to manage a separate community. Instead, we actively contribute to the Tunes <a href="https://discord.com/invite/S7tq8bUkAR" target="_blank"> discord</a>. Some ideas in the works to extend this project: (1) minting multiple $TLYRIC for each Tunes, (2) supporting distinct genres, (3) facilitating collaboration across Tunes via incentive layers.&nbsp;</p>
        <p class="ques"><strong>How can I take this forward?</strong>&nbsp;</p>
        <p class="ans">This is a derivative of the <a href="https://tunesproject.org/" target="_blank">Tunes Project</a>, and you can help further build on the composability chain. Write the full lyrics, add beats on top, sing it out loud, or heck, have a full-blown concert! Join the <a href="https://discord.com/invite/S7tq8bUkAR" target="_blank"> discord</a> for support and to find ways to contribute.</p>

      <br></br>
        <p class="love">Made with ☕️ by <a href="https://twitter.com/achyutjosh1995" target="_blank">Achyut Joshi</a> and <a href="https://twitter.com/vishalrohra_" target="_blank">Vishal Rohra</a>.</p>
        <br></br>
        <br></br>
        <br></br>
    </div>

  );
};

export default Minter;
