// import { pinJSONToIPFS } from "./pinata.js";
import axios from 'axios';
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("../contract-abi.json");
const contractAddress = "0x38B1e1061D7cc33f1C184C83FD2728785955Caa8";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);


export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tunes_nft = await getTunes(addressArray[0]);
      const obj = {
        status: "",
        address: addressArray[0],
        tunes_nft : tunes_nft,
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
        tunes_nft: {'success' : 0},
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      const tunes_nft = await getTunes(addressArray[0]);
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "",
          tunes_nft : tunes_nft,
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
          tunes_nft: {'success' : 0},
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};


export function renderImages(obj){
  const number = obj.length;
  const imageElements = [];
  for(let i = 0; i < number; i++){
      const imageSrc = obj[i].image_url;
      const imgId = obj[i].token_id;
      const imgTitle = obj[i].name;
      const href = obj[i].id+obj[i].name;
      const className = "image_tune";
      const spanClass="spanclass"
      const divParent="divParent"
      imageElements.push(<div className={divParent}><div ><p className={spanClass}>{imgId}</p></div><img src={imageSrc} id={imgId} className={className} alt="" /></div>);
  }
  return imageElements;
}

async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}

// mainnet - https://api.opensea.io/api/v1/assets
// testnet - https://testnets-api.opensea.io/api/v1/assets

async function getTunes(user_account) {
	const response = await axios.get('https://api.opensea.io/api/v1/assets', {
  params: {
    owner: user_account
    // owner:'0x9e6870952c5f7264aac963bb6afb1e0b97f63914'
  }});
  console.log(response)
  if (response.status == 200){
    console.log('Success Opensea API')
    const assets = response.data.assets
    const number_of_assets = assets.length
    console.log(number_of_assets)
    const tunes_nft = assets.filter(
      function(assets){ return assets.asset_contract.address == '0xfa932d5cbbdc8f6ed6d96cc6513153afa9b7487c' })
    const return_obj = {'success': 1, 'data' : tunes_nft}
    return(return_obj);
  }
  return({'success': 0});
};

export const mintNFT = async (tokenID) => {
  const tokenIdClean = tokenID.replace(/\s/g, "")
  // if (tokenID > 5000 || tokenID < 1) {
  //   return {
  //     success: false,
  //     status: "❗TokenID must be between 1 and 5000 and you should own that $TUNE",
  //   };
  // }

  console.log(tokenIdClean);

  var tokenIdCleanArray = tokenIdClean.split(',').map(function(item) {
    return parseInt(item, 10);
});

  //make metadata
  const metadata = new Object();
  // metadata.name = name;
  // metadata.image = url;
  // metadata.description = description;

  // const pinataResponse = await pinJSONToIPFS(metadata);
  // if (!pinataResponse.success) {
  //   return {
  //     success: false,
  //     status: "😢 Something went wrong while uploading your tokenURI.",
  //   };
  // }

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  console.log(tokenIdCleanArray)
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress,
    value: '0x2386F26FC10001', // must match user's active address.
    data: window.contract.methods
      .mintPublic(tokenIdCleanArray)
      .encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" +
        txHash,
    };
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};
